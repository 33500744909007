import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Accordion, Icon, Button, ButtonGroup, Form, Input, Loader, Message, AccordionTitleProps } from 'semantic-ui-react';
import StripeJs from "@stripe/stripe-js";
import styled from 'styled-components';

import Layout from '../components/Layout';
import { Config } from '../config';

type State = 'form' | 'once-off-payment' | 'recurring-payment' | 'success';

const amounts = [10, 25, 50, 100, 200, 500];
const cardOptions: StripeJs.StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

function createSubscription({ paymentMethodId, amount, email, firstName, lastName }) {
  return (
    fetch(`${Config.backend.url}/stripe/create-subscription`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        //customerId: customerId, //Don't need this, will create and use in the backend itself.
        paymentMethodId: paymentMethodId,
        amount: amount,
        email: email,
        firstName: firstName,
        lastName: lastName
      }),
    })
      .then((response) => {
        return response.json();
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          unit_amount: amount,
          subscription: result,
        };
      })
    // Some payment methods require a customer to be on session
    // to complete the payment process. Check the status of the
    // payment intent to handle these actions.
    //.then(handlePaymentThatRequiresCustomerAction)
    // If attaching this card to a Customer object succeeds,
    // but attempts to charge the customer fail, you
    // get a requires_payment_method error.
    //.then(handleRequiresPaymentMethod)
    // No more actions required. Provision your service for the user.
    //.then(onSubscriptionComplete)
    //.catch((error) => {
    // An error has happened. Display the failure to the user here.
    // We utilize the HTML element we created.
    //  showCardError(error);
    //})
  );
}

const StripeFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 2rem;
  text-align: right;
  background-color: white;
  border-radius: 5px;
  padding: 10px;

  span {
    margin-right: 10px;
    color: black;
  }

  @media only screen and (max-width : 768px) {
    text-align: center;
    grid-template-columns: 1fr;

    span {
      display: block;
    }
  }
`;

const CardRecurringForm = ({ className = '', onCancel, onSubmit, firstName, lastName, email, amount }) => {
  const numberOfParts = useRef<number>(3);
  const clientSecret = useRef<string>(null);
  const clientSubscription = useRef<string>(null);

  const [state, setState] = useState<'idle' | 'pending'>('idle');

  const [readyParts, setReadyParts] = useState([]);
  const elementsClass = readyParts.length >= numberOfParts.current ? '' : 'hidden';
  const stripe = useStripe();
  const elements = useElements();
  const canSubmit = stripe && elements;

  const onElementReady = () => setReadyParts((current) => current.concat(true));

  const subscribeUser = async (event) => {
    event.preventDefault();
    setState('pending');
    const cardNumberElement = elements.getElement(CardNumberElement);

    return stripe
      .createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      })
      .then((result) => {
        if (result.error) {
          console.error(result.error);
          alert('Something went wrong, please try that again')
          setState('idle');
        } else {
          createSubscription({
            paymentMethodId: result.paymentMethod.id,
            amount: amount,
            email: email,
            firstName: firstName,
            lastName: lastName
          }).then(() => {
            setState('idle');
            onSubmit();
          })
        }
      });
  };

  const loading = readyParts.length < numberOfParts.current || state === 'pending';

  return (
    <div className={className}>
      <Loader inline active={loading} />
      <StripeFormWrapper>
        {!loading && (<span>Card Number:</span>)}
        <CardNumberElement
          className={elementsClass}
          options={{
            ...cardOptions,
            showIcon: true,
          }}
          onReady={onElementReady}

        />
        {!loading && (<span>Card Expiry date:</span>)}
        <CardExpiryElement
          className={elementsClass}
          options={cardOptions}
          onReady={onElementReady}
        />
        {!loading && (<span>Card Security Code:</span>)}
        <CardCvcElement
          className={elementsClass}
          options={cardOptions}
          onReady={onElementReady}
        />
      </StripeFormWrapper>
      <div id="card-element-errors" role="alert"></div>
      <div className="buttons">
        <Button
          basic
          disabled={!canSubmit}
          inverted
          onClick={subscribeUser}
          type="submit"
        >
          Submit
        </Button>
        <Button
          basic
          inverted
          onClick={onCancel}
          type="reset"
        >
          Cancel
        </Button>
      </div>
    </div>
  );

};

const CardOnceOffForm = ({ className = '', onCancel, onSubmit, firstName, lastName, email, amount }) => {
  const numberOfParts = useRef<number>(3);
  const clientSecret = useRef<string>(null);
  const [state, setState] = useState<'idle' | 'pending'>('idle');

  const [readyParts, setReadyParts] = useState([]);
  const elementsClass = readyParts.length >= numberOfParts.current ? '' : 'hidden';
  const stripe = useStripe();
  const elements = useElements();
  const canSubmit = stripe && elements;

  const onElementReady = () => setReadyParts((current) => current.concat(true));

  useEffect(() => {
    (function () {
      fetch(`${Config.backend.url}/stripe/payment-intent`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          amount
        }),
      })
        .then(function (response) {
          return response.json();
        }).then(function (responseJson) {
          clientSecret.current = responseJson.client_secret;
          // setReadyParts((current) => current.concat(true));
          setState('idle');
        });
    })();
  }, []);

  const onSubmitPayment = async (event) => {
    event.preventDefault();

    if (!canSubmit) {
      return;
    }


    setState('pending');
    const cardNumberElement = elements.getElement(CardNumberElement);

    try {
      const response = await stripe.confirmCardPayment(clientSecret.current, {
        payment_method: {
          card: cardNumberElement,
        },
      });

      if (response.error) {
        alert('Something went wrong, please try that again.');
        throw response.error;
      }

      onSubmit();
    } catch (error) {
      console.error(error);
    } finally {
      setState('idle');
    }
  };

  const loading = readyParts.length < numberOfParts.current || state === 'pending';

  return (
    <div className={className}>
      <Loader inline active={loading} />
      <StripeFormWrapper>
        {!loading && (<span>Card Number:</span>)}
        <CardNumberElement
          className={elementsClass}
          options={{
            ...cardOptions,
            showIcon: true,
          }}
          onReady={onElementReady}

        />
        {!loading && (<span>Card Expiry date:</span>)}
        <CardExpiryElement
          className={elementsClass}
          options={cardOptions}
          onReady={onElementReady}
        />
        {!loading && (<span>Card Security Code:</span>)}
        <CardCvcElement
          className={elementsClass}
          options={cardOptions}
          onReady={onElementReady}
        />
      </StripeFormWrapper>
      <div className="buttons">
        <Button
          basic
          inverted
          disabled={!canSubmit}
          onClick={onSubmitPayment}
          type="submit"
        >
          Submit
        </Button>
        <Button
          basic
          inverted
          onClick={onCancel}
          type="reset"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const BankDetails = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const onClick = (event: React.MouseEvent<HTMLDivElement>, { index }: AccordionTitleProps) => {
    const newIndex: number = activeIndex === index ? -1 : index as number;

    setActiveIndex(newIndex);
  };

  return (
    <Accordion inverted fluid className="bank-details">
      <Accordion.Title active={activeIndex === 0} onClick={onClick} index={0}>
        <Icon name='dropdown' />
        Bank Details
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0} index={0}>
        Bank:&nbsp;AIB Lucan Branch
        <br />
        IBAN: IE46AIBK93356243997008
        <br />
        BIC:&nbsp;AIBKIE2D
        <br />
        Sort Code:&nbsp;93 35 62
      </Accordion.Content>
      <Accordion.Title active={activeIndex === 1} onClick={onClick} index={1}>
        <Icon name='dropdown' />
        Cheque & postal order details
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1} index={1}>
        Expectation&nbsp;Church
        <br />
        16&nbsp;Somerton&nbsp;Mews
        <br />
        Newcastle&nbsp;Road
        <br />
        Lucan
        <br />
        K78X7P2
      </Accordion.Content>
    </Accordion>
  );
};

const AmountButtonGroup = ({ onChange, inputRef }: { onChange: (amount: number) => void, inputRef: MutableRefObject<Input> }) => {
  return (
    <ButtonGroup size="mini">
      {amounts.map((amount, index) => (
        <Button
          basic
          type="button"
          inverted
          key={index}
          onClick={() => onChange(amount)}
        >
          €{amount}
        </Button>
      ))}
      <Button
        type="button"
        basic
        inverted
        onClick={() => {
          inputRef.current?.select();
          inputRef.current?.focus();
        }}
      >
        Custom
      </Button>
    </ButtonGroup>
  );
};

const Giving = () => {
  const amountInputRef = useRef<Input>(null);
  const [amount, setAmount] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setSecondName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [state, setState] = useState<State>('form');

  const canSubmit = amount > 0
    && firstName
    && lastName
    && email;

  useEffect(() => {
    // this is only needed while we're using the free heroku dyno
    fetch(Config.backend.url);
  }, []);

  useEffect(() => {
    // this is only needed while we're using the free heroku dyno
    fetch(Config.backend.url);
  }, [canSubmit]);

  return (
    <Layout className="giving">
      <article id="main">
        <header>
          <h1>
            Give
          </h1>
          <h3>Generosity is our joy</h3>
        </header>
        <section className={state === 'success' ? 'success' : ''}>
          {state !== 'success' ? (
            <p>
              Become part of the vision to bring Jesus to every heart & home. There are 3 easy ways to give: online,
              bank transfer or by cheques & postal orders.
            </p>
          ) : null}
          {state === 'form' ? (
            <Form>
              <AmountButtonGroup
                inputRef={amountInputRef}
                onChange={setAmount}
              />
              <Input
                fluid
                className="amount"
                value={amount}
                min={0}
                type="number"
                onChange={({ target: { value } }) => setAmount(parseFloat(value))}
                ref={amountInputRef}
                required
              />
              <Input
                inverted
                fluid
                className="comment"
                value={comment}
                placeholder="Comment (optional)"
                type="text"
                onChange={({ target: { value } }) => setComment(value)}
                required={false}
              />
              <Input
                fluid
                inverted
                className="first-name"
                value={firstName}
                placeholder="Your first name"
                type="text"
                onChange={({ target: { value } }) => setFirstName(value)}
                required
              />
              <Input
                fluid
                className="last-name"
                value={lastName}
                placeholder="Your last name"
                type="text"
                onChange={({ target: { value } }) => setSecondName(value)}
                required
              />
              <Input
                fluid
                className="email"
                value={email}
                placeholder="Your Email"
                type="email"
                onChange={({ target: { value } }) => setEmail(value)}
                required
              />
              <Button
                basic
                inverted
                onClick={() => setState('once-off-payment')}
                className="once-off"
                type="submit"
                disabled={!canSubmit}
              >
                Once Off
              </Button>
              <Button
                basic
                inverted
                onClick={() => setState('recurring-payment')}
                className="recurring"
                type="submit"
                disabled={!canSubmit}
              >
                Recurring (monthly)
              </Button>
              <BankDetails />
            </Form>
          ) : null}
          {state === 'once-off-payment' ? (
            <div className="payment">
              <h4>Once Off</h4>
              <p>Please fill in your banking details</p>
              <CardOnceOffForm
                firstName={firstName}
                lastName={lastName}
                email={email}
                amount={amount}
                onCancel={() => setState('form')}
                onSubmit={() => setState('success')}
              />
            </div>
          ) : null}
          {state === 'recurring-payment' ? (
            <div className="payment">
              <h4>Recurring Payment Setup</h4>
              <p>Please fill in your banking details</p>
              <CardRecurringForm
                firstName={firstName}
                lastName={lastName}
                email={email}
                amount={amount}
                onCancel={() => setState('form')}
                onSubmit={() => setState('success')}
              />
            </div>
          ) : null}
          {state === 'success' ? (
            <p>Thank you for partnering with what God is doing through Expectation Church.</p>
          ) : null}
        </section>
        <Message size="mini">Expectation Church is a registered charity. Charity Number: 20206284</Message>
      </article>
    </Layout >
  );
};

export default Giving;
